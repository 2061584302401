<template>
    <v-app>
        <div class="row">
            <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">

              <h3>Reviews</h3>
                <KTPortlet class="mt-2">
                    <template v-slot:body>
                        <div class="kt-invoice__body">
                            <div class="kt-invoice__container">
                                <div class="row" v-if="reviews.length<1">
                                    <div class="col-md-12 text-center">
                                        <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                                        <h5 class="mt-10">Looks like you have not added any data here. Trying adding
                                            some data.</h5>
                                    </div>
                                </div>
                                <v-data-table
                                        v-else
                                        :headers="fields"
                                        :items="reviews"
                                        :items-per-page="itemsPerPage"
                                        :single-expand="singleExpand"
                                        show-expand
                                        hide-default-footer
                                >
                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length">{{ item.description }}</td>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-switch
                                                v-model="item.is_active"
                                                @change="toogleIsActive(item)"
                                                label="Enable Review"
                                        ></v-switch>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>

                    </template>
                </KTPortlet>
            </div>
        </div>
        <v-pagination v-if="this.reviews.length>0"
                      v-model="page"
                      :length="pageCount"
                      prev-icon="mdi-menu-left"
                      next-icon="mdi-menu-right"
                      @input="getReviews(page)"
                      :total-visible="5"
        ></v-pagination>
    </v-app>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import ReviewService from "@/services/store/EcommerceProductReview";

    const reviewService = new ReviewService();
    export default {
        components: {
            KTPortlet
        },

        data() {
            return {
                reviews: [],
                page: 1,
                pageCount: null,
                itemsPerPage: 25,
                singleExpand: true,
                fields: [
                    {text: "Title", align: "start", sortable: false, value: "title"},
                    {text: "Reviewed By", align: "start", sortable: false, value: "full_name"},
                    {text: "Rating", align: "start", sortable: false, value: "rating"},
                    {text: "Reviewed At", align: "start", sortable: false, value: "reviewed_at"},
                    {
                        text: "Action",
                        align: "start",
                        sortable: false,
                        value: "action"
                    }
                ]
            };
        },
        mounted() {
            this.getReviews(this.page);
        },
        methods: {
            getReviews(page = null) {
                reviewService
                    .getReviews(page)
                    .then(response => {
                        // console.log(response)
                        this.reviews = response.data.data;
                        this.itemsPerPage = response.data.per_page;
                        this.page = response.data.current_page;
                        this.pageCount = response.data.last_page;
                    })
                    .catch(error => {
                    });
            }, toogleIsActive(item) {
                reviewService
                    .update(item.product_id, item.id, item)
                    .then(response => {
                        if (item.is_active) {
                            this.$snotify.success("Review Enabled");
                        } else {
                            this.$snotify.error("Review Disaled");
                        }
                        this.getReviews();
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            }
        }
    };
</script>
