import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class EcommerceProductReview {

      getReviews(page=null){
            let url = API_URL + 'user/ecommerce/reviews';
            if(page && page !== null && page !==undefined)
                  url = url + "?page="+page;
            return apiService.get(url);
      }

      paginate(productId){
            let url =  `${API_URL}user/ecommerce/product/${productId}/review`;
            return apiService.get(url);
      }

      update(productId, reviewId, data){
            let url =  `${API_URL}user/ecommerce/product/${productId}/review/${reviewId}`;
            return apiService.put(url, data);
      }
}
